import router from "./../../router";
import ProductionPlan from "./components/ProductionPlan";
import productionWorkers from "./crud/productionWorkers";
import productionFreeDays from "./crud/productionFreeDays";
import crud from "../../crud";
import store from "../../store";
import createListStore from "../../helpers/createListStore";
import moment from "moment";
import ProductionOrderList from "./components/ProductionOrderList";
import generateProperties from "../../helpers/generateProperties";
import AttendanceRegister from "./components/AttendanceRegister";
import LogisticList from "./components/LogisticList";
import productionLines from "./crud/productionLines";
import fixedOrderActivities from "./crud/fixedOrderActivities";

store.registerModule(
  "productionOrdersList",
  createListStore(
    `/orders?productionStatus=in_progress&productionDate[before]=${moment().format("YYYY-MM-DD")}` +
      generateProperties([],["id", "productionDate", "category", "number", "quantity"],true) +
      generateProperties(["fabricShadeOrderItems"], ["quantity"], true) +
      generateProperties(["externalShutterOrderItems"], ["quantity"], true) +
      generateProperties(["fabricShadeDayNightOrderItems"], ["quantity"], true) +
      generateProperties(["pleatOrderItems"], ["quantity"], true) +
      generateProperties(["detailOrderItems"], ["quantity"], true) +
      generateProperties(["frameMosquitoNetOrderItems"], ["quantity"], true) +
      generateProperties(["doorMosquitoNetOrderItems"], ["quantity"], true) +
      generateProperties(["pleatMosquitoNetOrderItems"], ["quantity"], true) +
      generateProperties(["rollMosquitoNetOrderItems"], ["quantity"], true) +
      generateProperties(["slideMosquitoNetOrderItems"], ["quantity"], true) +
      generateProperties(["company"], ["shortName"], true) +
      generateProperties(["subCompany"], ["shortName"], true) +
      generateProperties(["customer"],["type", "lastName", "city", "taxId", "companyName"],true) +
      generateProperties(["fitterProductionWorkers"], ["initials"], true) +
      generateProperties(["sawyerProductionWorkers"], ["initials"], true)
  )
);

store.registerModule(
  "logisticList",
  createListStore(
    `/orders?productionStatus=end&exists[shipmentDate]=false` +
    generateProperties([],["id", "productionDate", "category", "number", "shipmentDate"],true) +
    generateProperties(["fabricShadeOrderItems"], ["quantity"], true) +
    generateProperties(["externalShutterOrderItems"], ["quantity"], true) +
    generateProperties(["fabricShadeDayNightOrderItems"], ["quantity"], true) +
    generateProperties(["pleatOrderItems"], ["quantity"], true) +
    generateProperties(["detailOrderItems"], ["quantity"], true) +
    generateProperties(["frameMosquitoNetOrderItems"], ["quantity"], true) +
    generateProperties(["doorMosquitoNetOrderItems"], ["quantity"], true) +
    generateProperties(["pleatMosquitoNetOrderItems"], ["quantity"], true) +
    generateProperties(["rollMosquitoNetOrderItems"], ["quantity"], true) +
    generateProperties(["slideMosquitoNetOrderItems"], ["quantity"], true) +
    generateProperties(["company"], ["shortName"], true) +
    generateProperties(["subCompany"], ["shortName"], true) +
    generateProperties(["deliveryMethod"], ['id', "name"],true)
  )
);

router.addRoutes([
  {
    path: "/production/plan",
    name: "production_plan",
    component: ProductionPlan
  }
]);

router.addRoutes([
  {
    path: "/production/orders/list",
    name: "production_orders_list",
    component: ProductionOrderList
  }
]);

router.addRoutes([
  {
    path: "/production/attendance_register",
    name: "attendance_register",
    component: AttendanceRegister
  }
]);

router.addRoutes([
  {
    path: "/production/logistic_list",
    name: "logistic_list",
    component: LogisticList
  }
]);

crud(productionWorkers);
crud(productionFreeDays);
crud(productionLines);
crud(fixedOrderActivities);

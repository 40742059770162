import router from "./../../router";
import store from "./../../store";
import auth from "./store";
import http from "../../http";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import {
  onRequest,
  onRequestError,
  onResponse,
  onResponseError,
  refreshAuthLogic
} from "./http/interceptors";
import Login from "./views/Login";
import Forbidden from "./views/Forbidden";
import ChangePassword from "./views/ChangePassword";

router.addRoutes([
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: (to, from, next) => {
      if (auth.state.isLoggedIn) {
        next("/");
      }
      next();
    }
  },
  {
    path: "/forbidden",
    name: "forbidden",
    component: Forbidden
  },
  {
    path: "/change-password",
    name: "changePassword",
    component: ChangePassword,
    beforeEnter: (to, from, next) => {
      if (auth.state.isLoggedIn) {
        next("/");
      } else {
        next();
      }
    }
  }
]);
store.registerModule("auth", auth);

router.beforeEach((to, from, next) => {
  if (false === auth.state.isLoggedIn && to.name !== "login" && to.name !== "changePassword") {
    next({ name: "login" });
  }

  next();
});

http.interceptors.request.use(onRequest, onRequestError);
http.interceptors.response.use(onResponse, onResponseError);
//createAuthRefreshInterceptor(http, refreshAuthLogic);

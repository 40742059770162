import crud from "../../crud";
import orders from "./crud/orders";
import router from "./../../router";
import OrderShow from "./components/OrderShow";

crud(orders);

router.addRoutes([
  {
    path: "/orders/:id/show/:printType",
    name: "order_show",
    component: OrderShow,
    props: true
  }
]);

import router from "./router";
import store from "./store";
import http from "./http";
import notify from "./helpers/notify";

export default config => {
    let routes = [];
    if (config.list) {
        routes.push({
            path: config.list.route.path,
            name: config.list.route.name,
            props: config.list,
            component: () =>
                import(/* webpackChunkName: "dynamic" */ "./components/DynamicList"),
            beforeEnter(to, from, next) {
                let result = store.getters["auth/isAllowed"](config.list.permissions);
                if (result) {
                    return next();
                }
                next("/forbidden");
            }
        });
    }

    if (config.create) {
        routes.push({
            path: config.create.route.path,
            name: config.create.route.name,
            props: config.create,
            component: () =>
                import(/* webpackChunkName: "dynamic" */ "./components/DynamicCreate"),
            beforeEnter(to, from, next) {
                if (store.getters["auth/isAllowed"](config.list.permissions)) {
                    return next();
                }
                next("/forbidden");
            }
        });
    }

    if (config.update) {
        routes.push({
            path: config.update.route.path,
            name: config.update.route.name,
            props: config.update,
            component: () =>
                import(/* webpackChunkName: "dynamic" */ "./components/DynamicUpdate"),
            beforeEnter(to, from, next) {
                if (store.getters["auth/isAllowed"](config.list.permissions)) {
                    return next();
                }
                next("/forbidden");
            }
        });
    }

    if (config.refresh) {
        routes.push({
            path: config.refresh.route.path,
            name: config.refresh.route.name,
            props: config.refresh,
            beforeEnter(to, from, next) {
                if (store.getters["auth/isAllowed"](config.list.permissions)) {
                    http
                        .get(
                            `${config.refresh.route.path}`,
                        )
                        .then(response => {
                            notify("Odświeżono dane");
                            window.location.reload(); // refresh page
                            return next(from.path)
                        })
                        .catch(() => {
                            notify("Nie udało się odświeżyć danych.", "warning");
                        });

                    return next(from.path)
                }
                next("/forbidden");
            }
        });
    }

    if (config.export) {
        routes.push({
            path: config.export.route.path,
            name: config.export.route.name,
            beforeEnter(to, from, next) {
                if (store.getters["auth/isAllowed"](config.list.permissions)) {
                    http
                        .post(
                            `${config.export.route.path}?route_name=${config.export.route.name}`,
                            {
                                responseType: "blob"
                            },
                        )
                        .then(response => {
                            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
                            const a = document.createElement("a");
                            a.style.display = "none";
                            a.href = url;
                            a.download = `${config.export.route.name}.csv`;
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            document.body.removeChild(a);
                        })
                        .catch(() => {
                            notify("Nie udało się pobrać pliku", "warning");
                        });

                    return next(from.path)
                }
                next("/forbidden");
            }
        });
    }

    if (config.import) {
        routes.push({
            path: config.import.route.path,
            name: config.import.route.name,
            beforeEnter(to, from, next) {
                if (store.getters["auth/isAllowed"](config.list.permissions)) {
                    // Open dialog to input file and send to server too
                    const input = document.createElement('input');
                    input.type = 'file';
                    input.accept = '.csv' // accept only csv file

                    input.onchange = e => {
                        const file = e.target.files[0];
                        let formDataWithFile = new FormData();
                        formDataWithFile.append("file", file);
                        http
                            .post(
                                `${config.import.route.path}?route_name=${config.import.route.name}`,
                                formDataWithFile,
                                {
                                    headers: {
                                        "Content-Type": "multipart/form-data",
                                        "Accept": "application/ld+json"
                                    }
                                }
                            )
                            .then(response => {
                                const {ignoreRow} = response.data

                                if (ignoreRow) {
                                    const messageError = ignoreRow.map(el => "Element: " + el.item + " błąd:  " + el.error + "<br>").join(' ')
                                    notify("<h3>Importowanie przebiegło z błędami. <br>" + messageError, "warning", 100000, "is-top", () => {
                                        router.go(router.currentRoute)
                                    });
                                } else {
                                    notify("Importowanie przebiegło pomyślnie.", "info", 6000, "is-top", () => {
                                        router.go(router.currentRoute)
                                    });
                                }
                            })
                            .catch(() => {
                                notify("Nie udało się zaimportować pliku", "warning");
                            });
                    }

                    input.click();

                    return next(from.path)
                }
                next("/forbidden");
            }
        });
    }
    router.addRoutes(routes);
};

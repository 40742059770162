import crud from "../../crud";
import news from "./crud/news";
import supportInstructions from "@/modules/other/crud/supportInstructions";
import downloadSections from "@/modules/other/crud/downloadSections";
import downloadSubsections from "@/modules/other/crud/downloadSubsections";
import downloadFiles from "@/modules/other/crud/downloadFiles";
import bugReports from "./crud/bugReports";
import orderReclamations from "./crud/orderReclamations";
import router from "../../router";
import OrderShow from "../orders/components/OrderShow";
import orderReclamationShow from "./components/OrderReclamationShow";
import newsReads from "./crud/newsReads";

crud(news);
crud(newsReads);
crud(supportInstructions);
crud(downloadFiles);
crud(downloadSections);
crud(downloadSubsections);
crud(bugReports);
crud(orderReclamations);

router.addRoutes([
    {
        path: "/order_reclamations/:id/show/:printType",
        name: "order_reclamations_show",
        component: orderReclamationShow,
        props: true
    }
]);
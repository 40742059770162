import crud from "../../crud";

import statistic from "./crud/statistic";
import router from "../../router";
import StatisticsMain from "./components/StatisticsMain";
import CompanyStatistics from "./components/CompanyStatistics";

crud(statistic);

router.addRoutes([
    {
        path: "/statistics/main",
        name: "statistics_main",
        component: StatisticsMain
    },
    {
        path: "/statistics/company/:id",
        name: "statistics_company",
        component: CompanyStatistics
    }
]);
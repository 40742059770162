import router from "@/router";
import store from "@/store";

router.addRoutes([
  {
    path: "/configuration/:id",
    name: "configuration",
    component: () =>
      import(
        /* webpackChunkName: "dynamic" */ "@/modules/configuration/components/ConfigurationUpdate"
      ),
    beforeEnter(to, from, next) {
      if (store.getters["auth/isAllowed"](["configuration_read"])) {
        return next();
      }
      next("/forbidden");
    }
  }
]);
